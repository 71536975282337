<template>
  <!-- eslint-disable -->
  <div class="d-inline-flex">
    <!-- Show name and price if custom_integration exists -->
    <div v-if="custom_integration" style="padding: 5px;">
      <small class="font-weight-bolder">{{ capitalizeWords(type) }}: &nbsp;</small>
      <b-badge
        :class="canEdit ? 'cursor-pointer' : 'cursor-not-allowed'"
        :variant="canEdit ? 'primary' : 'secondary'"
        @click="canEdit ? showModal = true : null"
        class="mr-1"
      >
        <strong>{{ custom_integration.name }}</strong> - €{{ custom_integration.price }}
      </b-badge>
    </div>

    <!-- Button to add custom integration if it doesn't exist -->
    <b-button v-else variant="outline-info" size="sm" style="padding: 5px; margin: 7px 0;" class="btn" @click="showModal = true">
      Add {{ capitalizeWords(type) }}
    </b-button>

    <!-- Modal for Adding/Editing Custom Integration -->
    <b-modal v-model="showModal" :title="capitalizeWords(type)">
      <div class="form-group">
        <label for="integration-title">Title</label>
        <input type="text" class="form-control" id="integration-title" v-model="customIntegration.name"
          placeholder="Enter title" />
      </div>
      <div class="form-group">
        <label for="integration-price">Price</label>
        <input type="number" class="form-control" id="integration-price" v-model="customIntegration.price"
          placeholder="Enter price" />
      </div>
      <template v-slot:modal-footer>
          <b-button variant="danger" @click="deleteIntegration">Delete</b-button>
          <b-button variant="primary" @click="confirmIntegration">Save</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import { BBadge, BButton } from 'bootstrap-vue';
import axios from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BBadge,
    ToastificationContent,
  },
  props: {
    team_user_id: {
      type: Number,
      default: null,
      required: true,
    },
    user_id: {
      type: Number,
      default: null,
      required: true,
    },
    ticket_id: {
      type: Number,
      default: null,
      required: true,
    },
    custom_integration: {
      type: Object,
      default: null,
      required: false,
    },
    type: {
      type: String,
      default: 'integration',
      required: false,
    },
  },
  data() {
    return {
      showModal: false,
      oldName: '',
      customIntegration: {
        name: '',
        price: null,
      },
    };
  },
  computed: {
    canEdit() {
      return this.custom_integration && (this.custom_integration.invoice_id === null || this.custom_integration.status == 1);
    }
  },
  watch: {
    custom_integration: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.oldName = newVal.name;
          this.customIntegration.name = newVal.name;
          this.customIntegration.price = newVal.price;
        }
      },
    },
  },
  methods: {
    capitalizeWords(str) {
      return str.replace(/\b\w/g, char => char.toUpperCase());
    },
    confirmIntegration() {
      const payload = {
        name: this.customIntegration.name,
        price: this.customIntegration.price,
        user_id: this.user_id,
        team_user_id: this.team_user_id,
        ticket_id: this.ticket_id,
        old_name: this.oldName,
      };

      const apiUrl = this.custom_integration
        ? `/api/custom-integrations/${this.custom_integration.id}`
        : '/api/custom-integrations';

      const method = this.custom_integration ? 'patch' : 'post';

      axios[method](apiUrl, payload)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: `${this.type} saved successfully`,
              variant: 'success',
            },
          })
          this.showModal = false;
          //reload the page
          setTimeout(() => {
            location.reload();
          }, 500);
        })
        .catch((error) => {
          console.error("Error saving custom integration:", error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              text: 'Error saving custom integration',
              variant: 'danger',
            },
          })
        });
    },
    deleteIntegration() {
      axios.delete(`/api/custom-integrations/${this.custom_integration.id}`)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: `${this.type} deleted successfully`,
              variant: 'success',
            },
          })
          this.showModal = false;
          //reload the page
          setTimeout(() => {
            location.reload();
          }, 500);
        })
        .catch((error) => {
          console.error("Error deleting custom integration:", error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              text: `Error deleting ${this.type}`,
              variant: 'danger',
            },
          })
        });
    },
  },
};
</script>

<style scoped>
/* Styles to disable cursor when editing is not allowed */
.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}
</style>
