<template>
  <div>
    <ul>
      <li
        v-for="(item, index) in displayedHistory"
        :key="index"
      >
        <strong>{{ item.date }}</strong>  <em>{{ item.change }}</em>
      </li>
    </ul>
    <button
      v-if="showMoreButton"
      class="btn btn-success btn-sm"
      @click="showAll"
    >
      Show More
    </button>
  </div>
</template>

<script>
export default {
  props: {
    history: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      limit: 3,
      showAllItems: false,
    }
  },
  computed: {
    displayedHistory() {
      return this.showAllItems ? this.history : this.history.slice(0, this.limit)
    },
    showMoreButton() {
      return this.history.length > this.limit && !this.showAllItems
    },
  },
  methods: {
    showAll() {
      this.showAllItems = true
    },
  },
}
</script>

  <style scoped>
  /* Add your styles here */
  </style>
