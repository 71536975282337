<template>
  <div>
    <b-badge variant="secondary">
      {{ filename }}
      <feather-icon
        size="16"
        icon="XCircleIcon"
        class="cursor-pointer"
        @click="removeItem"
      />
    </b-badge>
  </div>
</template>
<script>
import {
  // eslint-disable-next-line no-unused-vars
  BBadge,
} from 'bootstrap-vue'

export default {
  components: { BBadge },
  props: {
    filename: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {}
  },
  computed: {},
  created() {},
  methods: {
    removeItem() {
      this.$emit('removeItemFromArray', this.index)
    },
  },
}
</script>
