<template>
  <!-- eslint-disable -->
   <span class="rating">
      <span
         v-for="(star, index) in 5"
         :key="index"
         :class="[justShow ? '' : 'star']"
         @click="setLevel(star)"
         :title="`${level} of 5 stars`"
      >
         <feather-icon
            v-if="star <= localLevel"
            icon="StarIcon"
            :style="greyStars ? 'color: black !important;' : 'color: #c23f8d;'"
         />
         <feather-icon v-else icon="StarIcon" style="color: #bfbfbfcc;" />
      </span>
   </span>
</template>

<script>
/* eslint-disable */
import { BButton, BButtonGroup, BIcon } from "bootstrap-vue";

export default {
   components: {
      BButton,
      BButtonGroup,
      BIcon,
   },
   props: {
      level: {
         type: Number,
         default: 1,
      },
      justShow: {
         type: Boolean,
         default: true,
      },
      greyStars: {
         type: Boolean,
         default: false,
      },
   },
   data() {
      return {
         localLevel: this.level,
      };
   },
   watch: {
      level(newLevel) {
         if (this.justShow) return; // Prevent changes if justShow is true
         this.localLevel = newLevel; // Sync localLevel with the prop if justShow is false
      },
   },
   methods: {
      setLevel(level) {
         if (this.justShow) return; // Prevent changes if justShow is true
         this.localLevel = level; // Update local level
         this.$emit("updateLevel", level); // Emit event to parent
      },
   },
};
</script>

<style scoped>
.rating {
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 3.5px;
   max-width: fit-content;
}

.star {
   cursor: pointer;
}
</style>
